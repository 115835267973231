/* Component Dependencies */
var recommendedServicesTemplate = require('templates/recommendedServices.hbs');
var AriesComponent = require('libs/aries-component');

AriesComponent.create({
  type: 'recommendedServices',
  template: {
    'recommendedServices': recommendedServicesTemplate
  }
});
